import { useCurrentUser } from '@/context/UserContext';

const PidLock = ({ additionalPIDs = [], children }: { additionalPIDs?: number[]; children: any }) => {
  const { currentUser } = useCurrentUser();

  const demoMode = localStorage.getItem('demoMode') ?? false;

  if ([5, 3113, 28764, ...additionalPIDs].includes(Number(currentUser.product.id)) && !demoMode) {
    return children;
  }

  return null;
};

PidLock.defaultProps = {
  additionalPIDs: [],
};

export default PidLock;
