import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Grid, IconButton } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '@/context/UserContext';
import { AbilityContext } from '@/context/Can';
import { AppProvider } from '@/context/AppContext';
import themeLightGoldilocks from 'goldilocks/themes/themeLightGoldilocks';
import themeDarkGoldilocks from 'goldilocks/themes/themeDarkGoldilocks';
import themeLightPaminga from 'goldilocks/themes/themeLightPaminga';
import themeDarkPaminga from 'goldilocks/themes/themeDarkPaminga';
import { LeftNav, NrAppBar } from '@/components/Navigation';
import defineAbilityFor from 'goldilocks/ability';
import MainContentWrapper from '@/components/Navigation/MainContentWrapper';
import { Close } from '@mui/icons-material';

const MainLayout = (props) => {
  const { currentUser } = useCurrentUser();
  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      // Send "page views" to NR capture process.
      if (import.meta.env.VITE_APP_ENVIRONMENT === 'production' && window.$__MA !== undefined) {
        window.$__MA.doCapture(`${import.meta.env.VITE_APP_ENDPOINT}${location.pathname}`, document.location, 5);
      }
    });
  }, [history]);

  const { hostname } = new URL(window.location.href);

  const themeLight = hostname === 'app.net-results.com' ? themeLightGoldilocks : themeLightPaminga;
  const themeDark = hostname === 'app.net-results.com' ? themeDarkGoldilocks : themeDarkPaminga;

  const currentTheme = currentUser.theme === 'themeDark' ? themeDark : themeLight;

  const snackbarStyles = makeStyles({
    success: {
      backgroundColor: currentTheme.palette.success.main,
      color: currentTheme.palette.success.contrastText,
      fontSize: '0.875rem',
    },
    warning: {
      backgroundColor: currentTheme.palette.warning.main,
      color: currentTheme.palette.warning.contrastText,
      fontSize: '0.875rem',
    },
    error: {
      backgroundColor: currentTheme.palette.error.main,
      color: currentTheme.palette.error.contrastText,
      fontSize: '0.875rem',
    },
    info: {
      backgroundColor: currentTheme.palette.info.main,
      color: currentTheme.palette.info.contrastText,
      fontSize: '0.875rem',
    },
  });
  const snackbarClasses = snackbarStyles();

  const ability = defineAbilityFor(currentUser);

  // todo: toggle favicon based on browser/OS dark mode
  // https://material-ui.com/customization/palette/#user-preference

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <AppProvider>
          <SnackbarProvider
            variant="success"
            maxSnack={4}
            preventDuplicate
            classes={{
              variantSuccess: snackbarClasses.success,
              variantError: snackbarClasses.error,
              variantWarning: snackbarClasses.warning,
              variantInfo: snackbarClasses.info,
            }}
            action={(snackbarId) => (
              <IconButton
                onClick={() => {
                  closeSnackbar(snackbarId);
                }}
              >
                <Close sx={{ color: 'white' }} />
              </IconButton>
            )}
          >
            <AbilityContext.Provider value={ability}>
              {/* <Grid container spacing={0} sx={{ minWidth: '1019px' }}> */}
              <Grid container spacing={0} sx={{ minWidth: '864px' }}>
                <Grid item xs={12}>
                  <NrAppBar />
                </Grid>
                <Grid item xs={12}>
                  <LeftNav />
                  <MainContentWrapper suppressNavTopMargin={false}>{props.children}</MainContentWrapper>
                </Grid>
              </Grid>
            </AbilityContext.Provider>
          </SnackbarProvider>
        </AppProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: '',
};

export default MainLayout;
