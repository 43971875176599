import React from 'react';
import { usePopupState, bindHover } from 'material-ui-popup-state/hooks';
import { Box, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { logout } from '@/utilities/jwtLocalstorage';
import Gravatar from '@/components/Gravatar';
import { useCurrentUser } from '@/context/UserContext';
import ThemeToggle from '@/components/Settings/Personal/ThemeToggle';
import { hilcoPids, hilcoSuperUsers } from '@/utilities/CustomerFeatures/hilco';
import CascadingSubmenu from '@/components/Navigation/CascadingSubmenu';
import CascadingMenu from '@/components/Navigation/CascadingMenu';
import PidLock from '@/components/PidLock';
import HilcoInstanceSwitcher from '../CustomerFeatures/HilcoInstanceSwitcher';

const NavMyAccount = () => {
  const { currentUser } = useCurrentUser();

  const hasSalesforce = !!currentUser.product?.salesforce_integration;
  const hasDynamics = !!currentUser.product?.dynamics_integration;
  const hasIFS = !!currentUser.product.crm_integrations?.find((crmIntegration) => crmIntegration.crm === 'IFS');

  const hasCRM = hasSalesforce || hasDynamics || hasIFS;

  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });

  const isHilcoSuperUser =
    Object.keys(hilcoPids).includes(String(currentUser.product.id)) &&
    hilcoSuperUsers.some((name) => currentUser.email.includes(name));

  return (
    <>
      <Box {...bindHover(popupState)}>
        <Gravatar
          email={currentUser.email}
          firstName={currentUser.first_name}
          lastName={currentUser.last_name}
          size={32}
        />
      </Box>
      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box pr={hasCRM ? '21px' : 0}>
          <Box ml={hasCRM ? '21px' : 0}>
            <Box display="flex" justifyContent="center">
              <ThemeToggle />
            </Box>
            {isHilcoSuperUser ? <HilcoInstanceSwitcher /> : null}

            {currentUser.authentication_source === undefined ||
            currentUser.authentication_source === 'USERNAME_PASSWORD' ? (
              <MenuItem
                component={Link}
                to="/settings/personal/password-change"
                sx={{
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Change Your Password
              </MenuItem>
            ) : null}

            <MenuItem
              component={Link}
              to="/settings/personal/profile"
              sx={{
                ':visited': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={popupState.close}
            >
              Personal Settings
            </MenuItem>
            <MenuItem
              component={Link}
              to="/settings/account/profile"
              sx={{
                ':visited': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={popupState.close}
            >
              Account Settings
            </MenuItem>
          </Box>

          {hasDynamics && (
            <CascadingSubmenu
              arrowDirection="left"
              popupId="dynamics"
              title="Dynamics Settings"
              menuItemUrl="/setup/account/integrations/dynamics/settings"
              onClick={popupState.close}
            >
              <MenuItem
                component={Link}
                to="/setup/account/integrations/dynamics/settings"
                sx={{
                  ml: '21px',
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Sync Settings
              </MenuItem>
              <CascadingSubmenu
                arrowDirection="left"
                popupId="dynamicsFieldMappings"
                title="Field Mappings"
                menuItemUrl="/setup/account/integrations/dynamics/field-mappings"
                onClick={popupState.close}
              >
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/dynamics/field-mappings#account"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Account Mappings
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/dynamics/field-mappings#contact"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Contact Mappings
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/dynamics/field-mappings#lead"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Lead Mappings
                </MenuItem>
              </CascadingSubmenu>
              <PidLock>
                <CascadingSubmenu
                  arrowDirection="left"
                  popupId="dynamicsActions"
                  title="Actions"
                  menuItemUrl="/setup/account/integrations/dynamics/actions#contact"
                  onClick={popupState.close}
                >
                  <MenuItem
                    component={Link}
                    to="/setup/account/integrations/dynamics/actions#contact"
                    sx={{
                      ':visited': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    onClick={popupState.close}
                  >
                    Contact Actions
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/setup/account/integrations/dynamics/actions#lead"
                    sx={{
                      ':visited': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    onClick={popupState.close}
                  >
                    Lead Actions
                  </MenuItem>
                </CascadingSubmenu>
              </PidLock>
              <MenuItem
                component={Link}
                to="/setup/account/integrations/dynamics/errors"
                sx={{
                  ml: '21px',
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Sync Errors
              </MenuItem>
            </CascadingSubmenu>
          )}

          {hasSalesforce && (
            <CascadingSubmenu
              arrowDirection="left"
              popupId="salesforce"
              title="Salesforce Settings"
              menuItemUrl="/setup/account/integrations/salesforce/settings"
              onClick={popupState.close}
            >
              <Box ml="21px">
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/salesforce/auth"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Authorization
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/salesforce/settings"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Sync Settings
                </MenuItem>
              </Box>
              <CascadingSubmenu
                arrowDirection="left"
                popupId="salesforceFieldMappings"
                title="Field Mappings"
                menuItemUrl="/setup/account/integrations/salesforce/field-mappings"
                onClick={popupState.close}
              >
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/salesforce/field-mappings#account"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Account Mappings
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/salesforce/field-mappings#contact"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Contact Mappings
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/salesforce/field-mappings#lead"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Lead Mappings
                </MenuItem>
              </CascadingSubmenu>
              <PidLock additionalPIDs={[28497, 28547]}>
                <CascadingSubmenu
                  arrowDirection="left"
                  popupId="salesforceActions"
                  title="Actions"
                  menuItemUrl="/setup/account/integrations/salesforce/actions#contact"
                  onClick={popupState.close}
                >
                  <MenuItem
                    component={Link}
                    to="/setup/account/integrations/salesforce/actions#contact"
                    sx={{
                      ':visited': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    onClick={popupState.close}
                  >
                    Contact Actions
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/setup/account/integrations/salesforce/actions#lead"
                    sx={{
                      ':visited': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    onClick={popupState.close}
                  >
                    Lead Actions
                  </MenuItem>
                </CascadingSubmenu>
              </PidLock>

              {/* Always show Salesforce sync actions if in demo mode. */}
              {localStorage.getItem('demoMode') ? (
                <CascadingSubmenu
                  arrowDirection="left"
                  popupId="salesforceActions"
                  title="Actions"
                  menuItemUrl="/setup/account/integrations/salesforce/actions#contact"
                  onClick={popupState.close}
                >
                  <MenuItem
                    component={Link}
                    to="/setup/account/integrations/salesforce/actions#contact"
                    sx={{
                      ':visited': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    onClick={popupState.close}
                  >
                    Contact Actions
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/setup/account/integrations/salesforce/actions#lead"
                    sx={{
                      ':visited': {
                        color: (theme) => theme.palette.text.primary,
                      },
                    }}
                    onClick={popupState.close}
                  >
                    Lead Actions
                  </MenuItem>
                </CascadingSubmenu>
              ) : null}

              <MenuItem
                component={Link}
                to="/setup/account/integrations/salesforce/errors"
                sx={{
                  ml: '21px',
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Sync Errors
              </MenuItem>
            </CascadingSubmenu>
          )}

          {hasIFS && (
            <CascadingSubmenu
              arrowDirection="left"
              popupId="ifsSettings"
              title="IFS Settings"
              menuItemUrl="/setup/account/integrations/ifs/settings"
              onClick={popupState.close}
            >
              <MenuItem
                component={Link}
                to="/setup/account/integrations/ifs/settings"
                sx={{
                  ml: '21px',
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Sync Settings
              </MenuItem>

              <CascadingSubmenu
                arrowDirection="left"
                popupId="ifsActions"
                title="Actions"
                menuItemUrl="/setup/account/integrations/ifs/actions#contact"
                onClick={popupState.close}
              >
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/ifs/actions#contact"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Contact Actions
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setup/account/integrations/ifs/actions#lead"
                  sx={{
                    ':visited': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                  onClick={popupState.close}
                >
                  Lead Actions
                </MenuItem>
              </CascadingSubmenu>

              <MenuItem
                component={Link}
                to="/setup/account/integrations/ifs/sync-status"
                sx={{
                  ml: '21px',
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Sync Status
              </MenuItem>

              <MenuItem
                component={Link}
                to="/setup/account/integrations/ifs/sync-history"
                sx={{
                  ml: '21px',
                  ':visited': {
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={popupState.close}
              >
                Sync History
              </MenuItem>
            </CascadingSubmenu>
          )}

          <Box ml={hasCRM ? '21px' : 0}>
            <MenuItem
              component={Link}
              to="/settings/account/billing#price-plan"
              sx={{
                ':visited': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={popupState.close}
            >
              Billing
            </MenuItem>
            <MenuItem
              component={Link}
              to="/roadmap"
              sx={{
                ':visited': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={popupState.close}
            >
              Product Roadmap
            </MenuItem>
            <a
              href="https://support.net-results.com"
              target="_blank"
              rel="noreferrer"
              style={{
                ':visited': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={popupState.close}
            >
              <MenuItem
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
                onClick={popupState.close}
              >
                Knowledge Base
              </MenuItem>
            </a>
            <MenuItem onClick={logout}>Sign Out</MenuItem>
          </Box>
        </Box>
      </CascadingMenu>
    </>
  );
};

export default NavMyAccount;
