import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

const HookFormFieldArray = ({ name, render }) => {
  const fieldArray = useFieldArray({
    name,
  });

  return render(fieldArray);
};

HookFormFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

export default HookFormFieldArray;
