import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import ApolloWrapper from '@/components/utilities/ApolloWrapper';
import { AuthProvider } from '@/context/AuthContext';
import UserProvider from '@/context/UserContext/UserProvider';
import { GenericErrorFallback } from '@/components/ErrorFallback';
import { getLocalStorageWithExpiry } from '@/utilities/localstorage';
import { ServiceWorkerProvider } from 'goldilocks/context/ServiceWorkerContext';
import App from './App';

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  beforeSend(event) {
    if (
      event?.message ===
        "InvalidStateError: Failed to update a ServiceWorker for scope ('https://app.net-results.com/') with script ('Unknown'): The object is in an invalid state." &&
      (event?.user?.email === 'regression@net-results.com' || event?.user?.product_id === 26372)
    ) {
      // Don't alert us of this kind of event from regression testing.
      // eslint-disable-next-line no-console
      console.log('InvalidStateError', event);
      return null;
    }
    return event;
  },
});

const currentUser = getLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY);

const errorFallback = <GenericErrorFallback />;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ServiceWorkerProvider>
    <Sentry.ErrorBoundary fallback={errorFallback}>
      <ApolloWrapper>
        <AuthProvider>
          <UserProvider currentUser={currentUser}>
            <App />
          </UserProvider>
        </AuthProvider>
      </ApolloWrapper>
    </Sentry.ErrorBoundary>
  </ServiceWorkerProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
