import React, { ReactNode } from 'react';
import { get } from 'lodash';
import { Box, Chip, Stack } from '@mui/material';
import { DateCell, DateTimeCell, NumberCell } from '@/components/Table/Cells';
import stringUtilities from '@/utilities/String';
import arrayUtilities from '@/utilities/Array/arrayUtilities';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
// @ts-ignore
import { Link } from 'react-router-dom';

type GqlField = {
  name: string;
  type: string;
  description: string | null;
  selected: boolean;
  readOnly: boolean;
};

type GqlType = {
  type: string;
  name: string;
  fields: GqlField[];
  children: GqlType[];
};

type RenderableType = {
  name: string;
  level: number;
  path: [];
  fields: Field[];
};

type Field = {
  name: string;
  type: string;
  description: string;
  hookFormFieldName: string;
  readOnly: boolean;
};

const createInitialValuesForSelectedColumns = (gqlType: GqlType) => {
  const columns: any = {};

  gqlType.fields.forEach((field: GqlField) => {
    columns[field.name] = field.selected;
  });

  if (gqlType?.children?.length > 0) {
    gqlType.children.forEach((childType) => {
      columns[childType.name] = createInitialValuesForSelectedColumns(childType);
    });
  }

  return columns;
};

// Add columns that are required, but should not be rendered as a column.
const columnsToSuppress = ['Download.download_url'];
const nonSortableColumns = ['Contact.salesforce_entity_type', 'Contact.salesforce_entity_type'];

export const assembleColumnsFromSelectedFields = (
  type: GqlType,
  path: string[] = [],
  onlyAllowSortOnTopLevel: boolean = false
): GridColDef[] => {
  const columns: GridColDef[] = [];

  const sortedFields: GqlField[] = sortFields(type.name, type.fields);

  sortedFields.forEach((field: GqlField): void => {
    if (field.selected && !columnsToSuppress.includes(`${type.type}.${field.name}`)) {
      let sortable = true;
      if (onlyAllowSortOnTopLevel || path.length > 0 || nonSortableColumns.includes(`${type.type}.${field.name}`)) {
        // Either this isn't the top level or this column is suppressed from being sortable
        sortable = false;
      }
      const fieldKey: string = path.length > 0 ? `${path.join('.')}.${field.name}` : field.name;

      // when fieldNameIsNotAnId is true, field is part of the GraphQL schema (ex. contact.first_name)
      // when it's false, it means the field is a contact custom field or similar. (we can tell because it's a number)
      // todo: account custom fields, later lead scores
      const fieldNameIsNotAnId = isNaN(Number(field.name));
      const headerName: string = fieldNameIsNotAnId
        ? // column header will be something like "Contact / First Name"
          stringUtilities.createCapWordsString(`${type.name} / ${field.name}`)
        : // column header will be something like "Custom Fields / {contact custom field name}"
          stringUtilities.createCapWordsString(`${type.name} / ${field.description}`);

      switch (field.type) {
        case 'Boolean':
        case 'BOOLEAN':
          columns.push({
            field: fieldKey,
            sortable,
            headerName,
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name);
            },
          });
          break;
        case 'DateTime':
        case 'DATETIME':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            headerAlign: 'right',
            align: 'right',
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name) ?? null;
            },
            groupingValueGetter: (params) => {
              return getFieldValue(params, path, field.name) ?? 'None';
            },
            renderCell: (params) => <DateTimeCell value={getFieldValue(params, path, field.name) ?? null} />,
          });
          break;
        case 'DATE':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            headerAlign: 'right',
            align: 'right',
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name) ?? null;
            },
            groupingValueGetter: (params) => {
              return getFieldValue(params, path, field.name) ?? 'None';
            },
            renderCell: (params) => <DateCell date={getFieldValue(params, path, field.name) ?? null} />,
          });
          break;
        case 'Int':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            headerAlign: 'right',
            align: 'right',
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name);
            },
            renderCell: (params) => <NumberCell value={getFieldValue(params, path, field.name)} />,
          });
          break;
        case 'String':
        case 'TEXT':
        case 'TEXTAREA':
        case 'EMAIL':
        case 'PHONE':
        case 'NUMBER': // Numbers do not use the NumberCell the 'Int' type uses because it displays empty values ("") as 0.
        case 'DECIMAL':
        case 'SELECT':
        case 'LOOKUP':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name);
            },
            ...renderCell(`${type.name}.${field.name}`, path),
          });
          break;
        case 'WEBSITE':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name);
            },
            renderCell: (params) =>
              getFieldValue(params, path, field.name) ? (
                <a href={getFieldValue(params, path, field.name)} target="_blank" rel="noreferrer">
                  {getFieldValue(params, path, field.name)}
                </a>
              ) : null,
          });
          break;
        case 'MULTI_SELECT':
        case 'MULTISELECT':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            valueGetter: (params) => {
              return getFieldValue(params, path, field.name);
            },
            renderCell: (params) => (
              <Stack direction="row" spacing={2}>
                {getMultiSelectCustomFieldValues(params, path, field.name)?.map((dropDownOptionName: string) => {
                  return <Chip key={dropDownOptionName} label={dropDownOptionName} variant="outlined" />;
                })}
              </Stack>
            ),
          });
          break;
        case 'CrmEntityType':
          columns.push({
            field: fieldKey,
            headerName,
            sortable,
            valueGetter: (params) => {
              return stringUtilities.capitalizeFirstLetter(getFieldValue(params, path, field.name) ?? '');
            },
            groupingValueGetter: (params) => {
              return stringUtilities.capitalizeFirstLetter(getFieldValue(params, path, field.name) ?? 'None');
            },
            ...renderCell(`${type.name}.${field.name}`, path),
          });
          break;
        default:
          // Do nothing?
          // ID field is an example?
          break;
      }
    }
  });

  if (type?.children?.length > 0) {
    type.children.forEach((childType: GqlType): void => {
      columns.push(...assembleColumnsFromSelectedFields(childType, [...path, childType.name], onlyAllowSortOnTopLevel));
    });
  }

  return columns;
};

// params.row will contain data of various shapes based on what field was chosen as the top level.
// Therefore, contact will be x levels down into params.row
// This function will return the contact/account/etc... regardless of it's position within the object by using path.
export function getValueFromPath(path: string[], rowData: any) {
  return path.reduce((currentValue, nextKey) => (currentValue ? currentValue[nextKey] : null), rowData);
}

const renderCell = (typeAndField: string, path: string[]) => {
  const sx = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
  switch (typeAndField) {
    case 'contact.email': {
      return {
        renderCell: (params: GridRenderCellParams<any, any, any>): ReactNode | string => {
          const contact = getValueFromPath(path, params.row);

          if (params.rowNode.type === 'group') {
            return <Box sx={sx}>{params.value}</Box>;
          }

          // If not grouped and contact is null, the contact has been deleted.
          if (params.rowNode.type === 'leaf' && !contact) {
            return <Box sx={sx}>(this contact has been deleted)</Box>;
          }

          return (
            <Box sx={sx}>
              <Link
                to={{
                  pathname: '/contact/detail',
                  search: `?contactId=${contact?.id}`,
                }}
              >
                {params.rowNode.type === 'leaf' && !contact?.email
                  ? '(this contact has a blank email address)'
                  : contact?.email}
              </Link>
            </Box>
          );
        },
      };
    }
    case 'account.name':
      return {
        renderCell: (params: GridRenderCellParams<any, any, any>): ReactNode => {
          const account = getValueFromPath(path, params.row);
          if (params.rowNode.type === 'group') {
            return <Box sx={sx}>{params.value}</Box>;
          }

          // If not grouped and account is null, the account has been deleted.
          if (params.rowNode.type === 'leaf' && !account) {
            return <Box sx={sx}>(this account has been deleted)</Box>;
          }
          return (
            <Box sx={sx}>
              <Link
                to={{
                  pathname: '/account/engagement',
                  search: `?accountId=${account?.id}`,
                }}
              >
                {account?.name}
              </Link>
            </Box>
          );
        },
      };
    case 'form.name':
      return {
        renderCell: (params: GridRenderCellParams<any, any, any>): ReactNode => {
          const form = getValueFromPath(path, params.row);
          if (params.rowNode.type === 'group') {
            return <Box sx={sx}>{params.value}</Box>;
          }
          // If not grouped and form is null, the form has been deleted.
          if (params.rowNode.type === 'leaf' && !form) {
            return <Box sx={sx}>(this form has been deleted)</Box>;
          }
          return (
            <Box sx={sx}>
              <Link
                to={{
                  pathname: '/marketing-center/forms/createEdit',
                  search: `?formId=${form?.id}`,
                }}
              >
                {form?.name}
              </Link>
            </Box>
          );
        },
      };
    case 'downloads.file_name': {
      return {
        renderCell: (params: GridRenderCellParams<any, any, any>): ReactNode | string => {
          const download = getValueFromPath(path, params.row);

          if (params.rowNode.type === 'group') {
            return <Box sx={sx}>{params.value}</Box>;
          }

          if (!download?.download_url) {
            return <Box sx={sx}>{download.file_name}</Box>;
          }

          return (
            <Box sx={sx}>
              <a href={`https://${download.download_url}`} target="_blank" rel="noopener noreferrer">
                {download.file_name}
              </a>
            </Box>
          );
        },
      };
    }
    default:
      return {
        renderCell: (params: GridRenderCellParams<any, any, any>): ReactNode => {
          return <Box sx={sx}>{params.value}</Box>;
        },
      };
  }
};

// Some types should always render certain columns first. Ensure that happens.
const sortFields = (typeName: string, fields: GqlField[]): GqlField[] => {
  switch (typeName) {
    case 'account': {
      const accountNameFieldIndex = fields.map((field) => field.name).indexOf('name');
      return arrayUtilities.moveArrayElement(fields, accountNameFieldIndex, 0);
    }
    case 'contact': {
      const emailFieldIndex = fields.map((field) => field.name).indexOf('email');
      return arrayUtilities.moveArrayElement(fields, emailFieldIndex, 0);
    }
    case 'form': {
      const formNameFieldIndex = fields.map((field) => field.name).indexOf('name');
      return arrayUtilities.moveArrayElement(fields, formNameFieldIndex, 0);
    }
    case 'formSubmissions': {
      const createdAtFieldIndex = fields.map((field) => field.name).indexOf('created_at');
      return arrayUtilities.moveArrayElement(fields, createdAtFieldIndex, 0);
    }
    case 'downloads': {
      const createdAtFieldIndex = fields.map((field) => field.name).indexOf('created_at');
      return arrayUtilities.moveArrayElement(fields, createdAtFieldIndex, 0);
    }
  }

  return fields;
};

const getFieldValue = (params: any, path: string[], fieldName: string): string => {
  // Had some issues while testing data in dev, this check shouldn't harm anything but fixes some off cases!
  if (!params || !params.row) {
    return '';
  }

  // if fieldName isn't a number, it's a standard part of the GraphQL schema (ex. contact.first_name)
  if (isNaN(Number(fieldName))) {
    return path.length > 0 ? get(params.row, `${path.join('.')}.${fieldName}`) : params.row[fieldName];
  }

  // if we get this far, fieldName is a numeric ID. It refers to a contact custom field or similar.
  // this is the value of contact.custom_fields. an array of objects.
  const customFieldsArray = get(params.row, `${path.join('.')}`);

  // find our contact custom field (fieldName is its ID)
  const customField = customFieldsArray
    ?.filter((field: any) => {
      return Number(field.id) === Number(fieldName);
    })
    ?.at(0);

  // when inside this if statement, the custom field must be a SELECT field. its value corresponds to a dropdown option, we find that to display the option's name.
  if (customField?.dropdown_options) {
    return customField.dropdown_options
      .filter((option: any) => {
        return option.id === customField?.value;
      })
      ?.at(0)?.value;
  }

  // when we have a relationship, it's a LOOKUP type. if any, return name of related item (ex. user/contact email, account name, custom object record name)
  if (customField?.relationship?.name) {
    return customField.relationship?.name;
  }

  // if no relationship or dropdown options, just returning the value will do fine.
  return customField?.value;
};

const getMultiSelectCustomFieldValues = (params: any, path: string[], fieldName: string): Array<string> => {
  // get the custom field we're dealing with.
  const customFieldsArray = get(params.row, `${path.join('.')}`);
  const customField = customFieldsArray
    ?.filter((field: any) => {
      return field.id === fieldName;
    })
    ?.at(0);

  // access all selected values of the MULTISELECT
  return customField?.dropdown_options
    ?.filter((option: any) => {
      return customField?.value?.includes(option.id);
    })
    ?.map((selectedOption: any) => {
      return selectedOption?.value;
    });
};

export const flattenGqlTypes = (types: RenderableType[]): RenderableType[] | [] => {
  const ogTypes: RenderableType[] = [...types];

  const typesToFilterOut: string[] = [];

  ogTypes.forEach((renderableType: RenderableType, index) => {
    switch (renderableType.name) {
      case 'AccountIndustry':
        moveSingleFieldToParent(types, renderableType, 'Industry', 2, typesToFilterOut);
        break;
      case 'AccountStatus':
        moveSingleFieldToParent(types, renderableType, 'Status', 3, typesToFilterOut);
        break;
      case 'AccountType':
        moveSingleFieldToParent(types, renderableType, 'Type', 3, typesToFilterOut);
        break;
      case 'MarketingTouch': {
        // Find the Source's `renderableType` associated with this MarketingTouch.
        const sourceType = types.find(
          (thisType) => thisType.path.join('.') === [...renderableType.path, 'source'].join('.')
        );

        // Change the labels of the MarketingTouch channel_label and created_at fields.
        const alteredTouchFields: Field[] = renderableType.fields.flatMap((field: Field): Field => {
          if (field.name === 'channel_label') {
            return { ...field, name: 'Marketing Channel' };
          }
          return { ...field, name: 'Created At' };
        });
        // Change the label of the "MarketingTouchSource.name" field to "Marketing Source".
        const alteredSourceFields: any = sourceType?.fields.map((field: Field): Field => {
          return { ...field, name: 'Marketing Source' };
        });

        // Is my parent a Contact or a Visit?
        const touchParentProperty = renderableType.path
          .slice(renderableType.path.length - 2, renderableType.path.length - 1)
          .join('');

        if (touchParentProperty === 'visit' || touchParentProperty === 'latest_visit') {
          // Remove the MarketingTouch.created_at field when the parent type is a Visit.
          // ...because the Visit already has a `created_at` field. We don't need to display two created_at fields.
          const marketingTouchFieldsForVisit = alteredTouchFields.filter((alteredTouchField) => {
            return alteredTouchField.name !== 'Created At';
          });

          // Splice the name fields into the parent (a Visit).
          const pathToParent = getPathToParentType(renderableType.path);
          const parentType = types.find((thisType) => thisType.path.join('.') === pathToParent.join('.'));
          if (parentType) {
            parentType.fields.splice(0, 0, ...marketingTouchFieldsForVisit, ...alteredSourceFields);
          }

          // Remove the MarketingTouch & MarketingTouchSource types.
          typesToFilterOut.push(renderableType.path.join('.'));
          if (sourceType) {
            typesToFilterOut.push(sourceType.path.join('.'));
          }
        }

        // MarketingTouch's parent is a `contact.
        // `touchParentProperty` will be an empty string for contact.first_touch, contact.last_touch, contact.last_touch_before[*]
        if (touchParentProperty === 'contact' || touchParentProperty === '') {
          const alteredType = { ...renderableType };

          // Pull the Created At Field out of the "Marketing Touch Fields" array...
          const createdAtField = alteredTouchFields.splice(1, 1);
          // ...and put it at the end so our Touch fields are in the proper order: Channel, Source, Created At.
          alteredType.fields = [...alteredTouchFields, ...alteredSourceFields, ...createdAtField];

          // Replace the MarketingTouch type with the alteredType.
          ogTypes.splice(index, 1, alteredType);
          if (sourceType) {
            typesToFilterOut.push(sourceType.path.join('.'));
          }
        }
        break;
      }
      case 'LeadStage':
        moveSingleFieldToParent(types, renderableType, 'Lead Stage', 5, typesToFilterOut);
        break;
      case 'VisitUtm':
        {
          // Prepend "UTM" onto each field name.
          const alteredFields: Field[] = renderableType.fields.map((field: Field): Field => {
            return { ...field, name: `UTM ${field.name}` };
          });

          const pathToParent = getPathToParentType(renderableType.path);

          const parentType = types.find((thisType) => thisType.path.join('.') === pathToParent.join('.'));
          // Splice all 5 UTM tag fields into my parentType (a Visit).
          if (parentType) {
            parentType.fields.splice(0, 0, ...alteredFields);
          }

          typesToFilterOut.push(renderableType.path.join('.'));
        }
        break;
      // no default
    }
  });

  return ogTypes.filter((type) => {
    return !typesToFilterOut.includes(type.path.join('.'));
  });
};

const getPathToParentType = (path: string[]): string[] => {
  const parentPath = [...path];
  parentPath.pop();
  return parentPath;
};

const moveSingleFieldToParent = (
  types: RenderableType[],
  renderableType: RenderableType,
  newFieldLabel: string,
  position: number,
  typesToFilterOut: string[]
) => {
  const alteredFields: Field[] = renderableType.fields.map((field: Field): Field => {
    return { ...field, name: newFieldLabel };
  });

  const pathToParent = getPathToParentType(renderableType.path);

  const parentType = types.find((thisType) => thisType.path.join('.') === pathToParent.join('.'));
  if (parentType) {
    parentType.fields.splice(position, 0, ...alteredFields);
  }

  typesToFilterOut.push(renderableType.path.join('.'));
};

export default createInitialValuesForSelectedColumns;
