export const hilcoPids = {
  22479: 'global',
  27700: 'hfar',
  27064: 'hre',
  27522: 'hsk',
  27465: 'hvsuk',
  27505: 'ivp4',
};

export const hilcoSuperUsers = ['bfichman', 'kbubrowski', 'jlechowicz'];
