import React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { useAuth } from '@/context/AuthContext';
import AuthenticatedApp from 'goldilocks/AuthenticatedApp';
import UnauthenticatedApp from 'goldilocks/UnauthenticatedApp';
import 'goldilocks/App.css';

// MUI-X License Key
LicenseInfo.setLicenseKey(
  '1ec74de3342dcd02701b386cb6b76c6dTz03ODI4NSxFPTE3MzEwNzk1MzIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

const App = () => {
  const { data } = useAuth();
  return data ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default App;
