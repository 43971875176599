import React, { useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Drawer } from '@mui/material';
import { ManageAccounts, Settings } from '@mui/icons-material';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '@/context/AppContext';
import SettingsToggle from '@/components/Settings/SettingsToggle';
import { useCurrentUser } from '@/context/UserContext';
import {
  navItemsWorkflows,
  navItemsContactsAccounts,
  navItemsPersonalSettings,
  navItemsAccountSettings,
  navItemsFormsLandingPages,
  navItemsEmails,
  navItemsLists,
  navItemsTextMessages,
  navItemsSalesforceIntegration,
  navItemsDynamicsIntegration,
  navItemsSugarCrmIntegration,
  navItemsIfsIntegration,
  leftNavItemsSocialPosting,
  navItemsWebinars,
} from '../constants';
import LeftNavTabs from './LeftNavTabs';

// todo: the arrays (like navItemsMarketingCenter) used for creating iconControls links must come from db and adhere to permissions.

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const NrDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const LeftNav = () => {
  const [leftNavHovered, setLeftNavHovered] = useState(false);
  const { leftNavExpanded, leftNavExpandedWidth, leftNavCollapsedWidth, leftNavDisabled } = useAppContext();
  const location = useLocation();
  const nrTheme = useTheme();
  const { headerHeight } = useAppContext();
  const { currentUser } = useCurrentUser();
  const expandLeftNav = leftNavExpanded || (!leftNavExpanded && leftNavHovered);
  const finalDrawerWidth = expandLeftNav ? leftNavExpandedWidth : leftNavCollapsedWidth;

  const transition = 'all 300ms ease 0s, opacity 300ms ease 0s';

  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      overflow: 'scroll',
      minHeight: '0',
    },
    show: {
      opacity: 1,
      transition,
    },
    hide: {
      opacity: 0,
      transform: 'translate3d(-15px, 0, 0)',
      transition,
      display: 'none',
    },
    drawerPaper: {
      width: finalDrawerWidth,
      transition,
      border: 'none',
      minHeight: '100vh',
      borderRight: '1px solid',
      borderRightColor: nrTheme.palette.borderColor,
      backgroundColor: nrTheme.palette.background.leftNav,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 18px',
      ...nrTheme.mixins.toolbar,
    },
    iconLayout: {
      marginRight: '0',
    },
  }));

  const classes = useStyles();
  const showHideLeftNavItems = clsx({
    [classes.show]: expandLeftNav,
    [classes.hide]: !expandLeftNav,
  });

  const handleLeftNavMouseEnter = () => {
    setLeftNavHovered(true);
  };

  const handleLeftNavMouseLeave = () => {
    setLeftNavHovered(false);
  };

  if (leftNavDisabled) {
    return null;
  }

  function filterAccountNavItems() {
    let items = navItemsAccountSettings;

    // PID Locked Items
    if (![5, 3113].includes(Number(currentUser.product.id))) {
      items = items.filter((item) => item.url !== '/settings/account/brand-kits');
    }
    // Dynamics
    if (!currentUser?.product?.dynamics_integration?.id) {
      items = items.filter((item) => item.url !== '/setup/account/integrations/dynamics/settings');
    }
    // Salesforce
    if (!currentUser?.product?.salesforce_integration?.id) {
      items = items.filter((item) => item.url !== '/setup/account/integrations/salesforce/settings');
    }

    return items;
  }

  function filterContactAccountNavItems() {
    let items = navItemsContactsAccounts;

    // PID Locked Items
    if ([5, 3113, 28497, 28547].includes(Number(currentUser.product.id))) {
      items = [
        ...items,
        {
          url: '/leads',
          label: 'Leads',
          icon: 'people',
        },
        {
          url: '/opportunities',
          label: 'Opportunities',
          icon: 'opportunities',
        },
      ];
    }

    // Data Enrichment
    if (
      currentUser?.product?.data_enrichment_integrations &&
      currentUser.product.data_enrichment_integrations.length > 0
    ) {
      items = [
        ...items,
        {
          url: '/data-enrichment/integrations',
          label: 'Enrichment Integrations',
          icon: 'settingsInputComponent',
        },
        {
          url: '/data-enrichment/revisions',
          label: 'Enrichment Revisions',
          icon: 'grading',
        },
        {
          url: '/data-enrichment/history',
          label: 'Enrichment History',
          icon: 'history',
        },
      ];
    }

    if (currentUser?.product?.salesforce_integration?.person_account) {
      items = [
        ...items,
        {
          url: '/person-accounts',
          label: 'Person Accounts',
          icon: 'people',
        },
      ];
    }

    return items;
  }

  return (
    <nav className={classes.root}>
      <NrDrawer
        onMouseEnter={handleLeftNavMouseEnter}
        onMouseLeave={handleLeftNavMouseLeave}
        anchor="left"
        variant="permanent"
        open={expandLeftNav}
        sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box sx={{ mt: `${headerHeight}px`, mb: 2, position: 'relative' }}>
          <Route
            path={[
              '/marketing-center/automations',
              '/marketing-center/drip-series',
              '/reporting/dashboards/automation-overview',
              '/reporting/dashboards/automation-performance',
              '/reporting/dashboards/automation-comparison',
            ]}
          >
            <LeftNavTabs
              navItems={navItemsWorkflows}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/setup/account/integrations/dynamics']}>
            <LeftNavTabs
              navItems={navItemsDynamicsIntegration}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/setup/account/integrations/salesforce']}>
            <LeftNavTabs
              navItems={navItemsSalesforceIntegration}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/setup/account/integrations/ifs']}>
            <LeftNavTabs
              navItems={navItemsIfsIntegration}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/setup/account/integrations/sugarcrm']}>
            <LeftNavTabs
              navItems={navItemsSugarCrmIntegration}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/marketing-center/sms']}>
            <LeftNavTabs
              navItems={navItemsTextMessages}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route
            path={[
              '/marketing-center/lists',
              '/marketing-center/subscriptions',
              '/marketing-list/dashboard',
              '/subscription-list/dashboard',
            ]}
          >
            <LeftNavTabs
              navItems={navItemsLists}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route
            path={[
              '/marketing-center/emails',
              '/marketing-center/email-templates',
              '/marketing-center/email-preferences',
            ]}
          >
            <LeftNavTabs
              navItems={navItemsEmails}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route
            path={[
              '/marketing-center/forms',
              '/marketing-center/nextGenForms',
              '/marketing-center/mapped-forms',
              '/reporting/dashboards/conversionsiframe',
              '/marketing-center/landing-pages',
            ]}
          >
            <LeftNavTabs
              navItems={navItemsFormsLandingPages}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route
            path={[
              '/marketing-center/social/accounts',
              '/marketing-center/social/posts/create',
              '/marketing-center/social/post/edit',
              '/marketing-center/social/post/clone',
              '/marketing-center/social/calendar',
              '/marketing-center/social/list-view',
              '/reporting/dashboards/social-page-overview',
            ]}
          >
            <LeftNavTabs
              navItems={leftNavItemsSocialPosting}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route
            path={[
              '/account/contacts',
              '/account/activityStream',
              '/account/engagement',
              '/account/detail',
              '/account/notes',
              '/accounts',
              '/contacts',
              '/contact/detail',
              '/contact/fields',
              '/contact',
              '/custom-object',
              '/custom-objects',
              '/contact-imports',
              '/data-enrichment',
              '/leads',
              '/opportunities',
              '/person-accounts',
              '/person-account',
            ]}
          >
            <LeftNavTabs
              navItems={filterContactAccountNavItems()}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path="/settings/personal">
            <Box p={2} pb={1}>
              {expandLeftNav ? (
                <SettingsToggle settingsCategory="Personal" />
              ) : (
                <ManageAccounts sx={{ ml: '7px', mt: '10px' }} />
              )}
            </Box>
            <LeftNavTabs
              navItems={navItemsPersonalSettings}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/settings/account', '/billingStatement']}>
            <Box p={2} pb={1}>
              {expandLeftNav ? (
                <SettingsToggle settingsCategory="Account" />
              ) : (
                <Settings sx={{ ml: '6px', mt: '10px' }} />
              )}
            </Box>
            <LeftNavTabs
              navItems={filterAccountNavItems()}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
          <Route path={['/marketing-center/webinars', '/reporting/dashboards/webinar']}>
            <LeftNavTabs
              navItems={navItemsWebinars}
              showHideSidebarItems={showHideLeftNavItems}
              locationPath={location.pathname}
            />
          </Route>
        </Box>
      </NrDrawer>
    </nav>
  );
};

export default LeftNav;
