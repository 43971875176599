import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useCurrentUser } from '@/context/UserContext';
import { usePopupState, bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { hilcoPids, hilcoSuperUsers } from '@/utilities/CustomerFeatures/hilco';
import { SubProductsQuery } from 'goldilocks/graphql/user/queries.graphql';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/utilities/localstorage';
import { useAuth } from '@/context/AuthContext';
import DataLoading from '@/components/DataLoading';
import { LoadingErrorFallback } from '@/components/ErrorFallback';

const HilcoInstanceSwitcher = () => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { data, loading, error } = useQuery(SubProductsQuery);
  const { reload } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const displaySnackbar = useCallback(
    (message, variant) => {
      enqueueSnackbar(message, {
        variant,
      });
    },
    [enqueueSnackbar]
  );

  const logInUser = useCallback(
    (user, productId) => {
      const ttl = user.product.session_duration * 60 * 60 * 1000;
      // TODO: set this as a env
      const parentUserInStorage = getLocalStorageWithExpiry('PARENT_USER');
      if (!parentUserInStorage) {
        const parentAuthToken = getLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY);
        const parentUser = { ...currentUser, auth_token: parentAuthToken };
        setLocalStorageWithExpiry('PARENT_USER', parentUser, ttl);
      }

      setCurrentUser(user);
      setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY, user, ttl);
      setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY, user.auth_token, ttl);
      reload();
      popupState.close();
      displaySnackbar(`You are now logged into the ${hilcoPids[productId].toUpperCase()} instance.`, 'success');
    },
    [currentUser, displaySnackbar, popupState, reload, setCurrentUser]
  );

  const handleClick = useCallback(
    (productId) => {
      if (data) {
        let userToLogin = data.subProducts.find((subProduct) => String(subProduct.product_id) === String(productId));

        if (userToLogin) {
          userToLogin = userToLogin.login_user;
        }
        if (!userToLogin) {
          const parentUserInStorage = getLocalStorageWithExpiry('PARENT_USER');
          if (parentUserInStorage) {
            userToLogin = String(productId) === String(parentUserInStorage.product.id) ? parentUserInStorage : null;
          }
        }
        if (userToLogin) {
          logInUser(userToLogin, productId);
        }
        if (!userToLogin) {
          displaySnackbar(`We could not log you in to the ${hilcoPids[productId].toUpperCase()} instance.`, 'error');
        }
      }
    },
    [data, logInUser, displaySnackbar]
  );

  if (
    !Object.keys(hilcoPids).includes(String(currentUser.product.id)) ||
    !hilcoSuperUsers.some((name) => currentUser.email.includes(name))
  ) {
    return null;
  }

  const instances = [];
  Object.keys(hilcoPids).forEach((pid, index) => {
    instances.push(
      <MenuItem
        key={pid}
        onClick={() => {
          handleClick(pid);
        }}
        sx={{
          minWidth: '170px',
        }}
      >
        {/* <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, mr: -1 }}>{menuItemIcon}</ListItemIcon> */}
        <ListItemText primary={hilcoPids[pid].toUpperCase()} />
      </MenuItem>
    );
  });

  if (loading) {
    return <DataLoading size={20} padding="10px" />;
  }

  if (error) {
    return <LoadingErrorFallback error={error} />;
  }

  return (
    <>
      <MenuItem sx={{ cursor: 'pointer' }} {...bindTrigger(popupState)}>
        <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, ml: -1 }}>
          <ChevronLeft {...bindTrigger(popupState)} />
        </ListItemIcon>
        <ListItemText sx={{ ml: -1 }}>Switch Instance</ListItemText>
      </MenuItem>

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...bindMenu(popupState)}
      >
        {instances}
      </Menu>
    </>
  );
};

export default HilcoInstanceSwitcher;
