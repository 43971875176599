import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useCurrentUser } from '@/context/UserContext';
import MainLayout from 'goldilocks/layouts/MainLayout';
import NoNavigationLayout from 'goldilocks/layouts/NoNavigation';
import IcpCustomerSurvey from 'goldilocks/pages/Surveys/IcpCustomerSurvey';
import Routes from '../routes';
import LenovoRoutes from '../lenovoRoutes';
import CrmPanelRoutes from '../crmPanelRoutes';

const AuthenticatedApp = () => {
  const { currentUser } = useCurrentUser();

  if (currentUser) {
    Sentry.setUser({
      product_id: currentUser.product.id,
      customer_id: currentUser.product.customer_id,
      user_id: currentUser.id,
      email: currentUser.email,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
    });
  }

  let componentToRender = (
    <MainLayout>
      <Routes />
    </MainLayout>
  );

  // UserId=109582 (afreeman@esg.us)
  // UserId=109583 (bshuff@esg.us)
  // UserId=109584 (naamodt@esg.us)
  // UserId=110364 (justin+lenovo@7knotsdigital.com)
  // UserId=110365 (brooke+lenovo@7knotsdigital.com)
  // UserId=111222 (email not provided by Lacey)
  const lenovoUserIds = [109582, 109583, 109584, 110364, 110365, 111222];
  if (lenovoUserIds.includes(Number(currentUser?.id))) {
    componentToRender = (
      <NoNavigationLayout>
        <LenovoRoutes />
      </NoNavigationLayout>
    );
  }

  // can use useLocation cause this is above the Router
  if (['/crm/panel/account', '/crm/panel/contact'].includes(window.location.pathname)) {
    componentToRender = (
      <NoNavigationLayout disableLogout>
        <CrmPanelRoutes />
      </NoNavigationLayout>
    );
  }

  // can use useLocation cause this is above the Router
  if (['/survey/icp'].includes(window.location.pathname)) {
    componentToRender = (
      <NoNavigationLayout disableLogout>
        <IcpCustomerSurvey />
      </NoNavigationLayout>
    );
  }

  return <Router>{componentToRender}</Router>;
};

export default AuthenticatedApp;
