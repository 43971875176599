import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import FormatDate from '@/utilities/DateTime';

const DateCell = ({ date }) => {
  return (
    <Box sx={{ textAlign: 'right' }}>
      <FormatDate date={date} />
    </Box>
  );
};
DateCell.propTypes = {
  date: PropTypes.node,
};

DateCell.defaultProps = {
  date: '',
};

export default DateCell;
