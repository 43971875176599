import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, Slide } from '@mui/material';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import DialogTitle from '@/components/core/DialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={1700} ref={ref} {...props} />;
});

const VideoDialog = ({ title, trigger, videoEmbedUrl }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: uuidv4() });

  return (
    <>
      <Box {...bindTrigger(popupState)}>{trigger}</Box>
      <Dialog
        maxWidth="xl"
        onClose={() => {
          popupState.close();
        }}
        TransitionComponent={Transition}
        aria-labelledby="video"
        {...bindPopover(popupState)}
      >
        <DialogTitle
          title={title}
          onClose={() => {
            popupState.close();
          }}
        />
        <DialogContent sx={{ minWidth: 'calc(100vw - 100px)', height: 'calc(100vh - 96px)', backgroundColor: '#000' }}>
          <Box p={2} mt={8} justifyContent="center" alignItems="center">
            <br />
            <br />
            <iframe
              title={title}
              src={`${videoEmbedUrl}?hideEmbedTopBar=true`}
              allowFullScreen
              style={{ position: 'absolute', top: 70, left: 0, width: '100%', height: '90%', border: 'none' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

VideoDialog.defaultProps = {
  title: null,
};

VideoDialog.propTypes = {
  title: PropTypes.string,
  trigger: PropTypes.node.isRequired,
  videoEmbedUrl: PropTypes.string.isRequired,
};

export default VideoDialog;
