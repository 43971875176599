import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Stack } from '@mui/material';

const DataLoading = (props, { style }) => {
  return (
    <Stack direction="column" spacing={2} alignItems="center" sx={{ pt: props.padding, width: '100%', ...style }}>
      <CircularProgress size={props.size} />
      {props.message ? props.message : null}
    </Stack>
  );
};

DataLoading.propTypes = {
  message: PropTypes.node,
  padding: PropTypes.number,
  size: PropTypes.number,
};

DataLoading.defaultProps = {
  message: null,
  padding: 5,
  size: 35,
};

export default DataLoading;
