import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useCurrentUser } from '@/context/UserContext';
import moment from 'moment';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormTime = ({ color, disabled, fullWidth, helperText, label, minutesStep, name, required, onChange, sx }) => {
  const [value, setValue] = useState('');
  const { currentUser } = useCurrentUser();

  const findValue = (internalValue, fieldValue) => {
    // if the user has interacted with the picker, we start to use the internalValue (a moment object)
    if (internalValue) {
      return internalValue;
    }

    // on first load with an fieldValue of a string date, we have to parse and return it
    if (fieldValue !== '') {
      // when editing existing values returned from GraphQL: convert UTC time to local time.
      const utc = moment.utc(fieldValue);
      return moment(utc).tz(currentUser.time_zone);
    }
    return moment(fieldValue);
  };

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              label={label}
              value={findValue(value, field.value)}
              minutesStep={minutesStep}
              ampm={currentUser.time_format === 'h:mm A'}
              onChange={(newValue) => {
                // set internal value (newValue is a moment object)
                setValue(newValue); // this displays to the user. TimePicker formats it based on its ampm prop.
                onChange(newValue);
                // set the form field value to iso string
                // the argument passed to field.onChange is what goes to the form's onSubmit.
                field.onChange(newValue.clone().utc().format('YYYY-MM-DD HH:mm:ss'));
              }}
              disabled={disabled || isSubmitting}
              slotProps={{
                textField: {
                  size: 'small',
                  name: field.name,
                  onBlur: field.onBlur,
                  required,
                  fullWidth,
                  error: invalid,
                  helperText: error ? error.message : helperText,
                  sx,
                },
                field: {
                  onChange: (newValue) => {
                    // this handles changes from the input via keyboard
                    if (newValue) {
                      // set internal value (moment object)
                      setValue(newValue);
                      onChange(newValue);
                      // set the form field value to iso string
                      // the argument passed to field.onChange is what goes to the form's onSubmit.
                      field.onChange(newValue.clone().utc().format('YYYY-MM-DD HH:mm:ss'));
                    }
                  },
                  inputRef: field.ref,
                },
                openPickerButton: {
                  color,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
      name={name}
    />
  );
};

HookFormTime.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  minutesStep: PropTypes.number,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

HookFormTime.defaultProps = {
  color: 'secondary',
  disabled: false,
  fullWidth: false,
  helperText: null,
  minutesStep: 1,
  required: false,
  onChange: () => {},
  sx: {},
};

export default HookFormTime;
