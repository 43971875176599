import React, { useState } from 'react';
import { FormHelperText, Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const HookFormMatchingFields = ({
  label,
  name,
  onChange = () => {},
  required = false,
  secondLabel = undefined,
  spacing = 2,
}: {
  label: string;
  name: string;
  onChange?: (value: any) => void;
  required?: boolean;
  secondLabel?: string;
  spacing?: number | string;
}) => {
  // Will render two text fields. If both values match, the field will equal either value.
  // If they do not match, the field will equal false.
  // Tried to dynamically inject validation rule from this controller to resolver but couldn't find a way
  // As the resolver seems to trump everything. In that case, you'll need to add this to your Yup validation:
  // filedName: Yup.string()
  //       .test('valuesMatch', 'Values do not match', (value) => {
  //         return value !== 'false';
  //       })

  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const { setError, clearErrors } = useFormContext();

  function onValueUpdate(field: any, value: false | string) {
    field.onChange(value);
    onChange(value);

    if (value) {
      // If the values match while editing, remove the error.
      clearErrors(name);
    }
  }

  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
        <Stack spacing={spacing} sx={{ width: '100%' }}>
          <TextField
            size="small"
            label={label}
            onChange={(event) => {
              setFirstValue(event.target.value);
              onValueUpdate(field, event.target.value === secondValue ? event.target.value : false);
            }}
            disabled={isSubmitting}
            error={!!error && error.type === 'required'}
            required={required}
          />
          <Stack>
            <TextField
              size="small"
              label={secondLabel ?? label}
              onChange={(event) => {
                setSecondValue(event.target.value);
                onValueUpdate(field, firstValue === event.target.value ? firstValue : false);
              }}
              onBlur={() => {
                // Validate if the two values match after clicking off the second text input.
                if (!field.value) {
                  setError(name, { type: 'custom', message: 'Values do not match.' });
                }
              }}
              error={!!error}
              disabled={isSubmitting}
              required={required}
            />
            <FormHelperText error>{error ? error.message : null}</FormHelperText>
          </Stack>
        </Stack>
      )}
    />
  );
};

HookFormMatchingFields.defaultProps = {
  onChange: () => {},
  required: false,
  secondLabel: undefined,
  spacing: 2,
};

export default HookFormMatchingFields;
