import React from 'react';
import { ThemeProvider, StyledEngineProvider, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Slide, Stack, Typography } from '@mui/material';
import themeLightGoldilocks from 'goldilocks/themes/themeLightGoldilocks';
import themeLightPaminga from 'goldilocks/themes/themeLightPaminga';
import ForgotPassword from '@/components/Auth/ForgotPassword';
import ResetPassword from '@/components/Auth/ResetPassword';
import AccountLogin from '@/components/Auth/AccountLogin';
import CrmIframe from '@/components/Auth/CrmIframe';
import Backdoor from '@/components/Auth/Backdoor';
import SingleSignOn from '@/components/Auth/SingleSignOn';
import NrLogo from '@/components/NrLogo';
import LoginPageMessages from '@/components/LoginPageMessages';
import queryString from 'query-string';

const AuthLayout = () => {
  const location = useLocation();
  const currentTheme = useTheme();
  const snackbarStyles = makeStyles({
    success: { backgroundColor: currentTheme.palette.success.main, color: currentTheme.palette.success.contrastText },
    warning: { backgroundColor: currentTheme.palette.warning.main, color: currentTheme.palette.warning.contrastText },
    error: { backgroundColor: currentTheme.palette.error.main, color: currentTheme.palette.error.contrastText },
    info: { backgroundColor: currentTheme.palette.info.main, color: currentTheme.palette.info.contrastText },
  });
  const snackbarClasses = snackbarStyles();
  let componentToRender;
  let pageLabel;
  let welcomeMessage;
  const queryParams = queryString.parse(window.location?.search, {
    parseNumbers: true,
  });

  const { hostname } = new URL(window.location.href);
  const isGoldilocks = hostname === 'app.net-results.com';

  switch (location.pathname) {
    case '/forgot-password':
      componentToRender = <ForgotPassword />;
      pageLabel = 'PASSWORD RESET';
      break;
    case '/set-password':
      componentToRender = <ResetPassword buttonLabel="Set Your Password" />;
      pageLabel = 'SET YOUR PASSWORD';
      break;
    case '/reset':
      componentToRender = <ResetPassword buttonLabel="Reset Password" />;
      pageLabel = 'RESET YOUR PASSWORD';
      break;
    default:
      componentToRender = <AccountLogin />;
      pageLabel = 'ACCOUNT LOGIN';
      welcomeMessage = 'Welcome to Goldilocks';
      break;
  }

  if (location.pathname === '/backdoor') {
    return <Backdoor />;
  }

  if (location.pathname === '/crm-iframe') {
    return <CrmIframe />;
  }

  if (location.pathname === '/sso') {
    return <SingleSignOn />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={isGoldilocks ? themeLightGoldilocks : themeLightPaminga}>
        <CssBaseline />
        <SnackbarProvider
          variant="success"
          maxSnack={4}
          preventDuplicate
          classes={{
            variantSuccess: snackbarClasses.success,
            variantError: snackbarClasses.error,
            variantWarning: snackbarClasses.warning,
            variantInfo: snackbarClasses.info,
          }}
        >
          <Grid container spacing={0} sx={{ minWidth: 900 }}>
            <Grid item xs={7}>
              <Slide direction="right" in mountOnEnter unmountOnExit>
                <Box
                  sx={{
                    mt: { xs: 2, sm: 4 },
                    px: 2,
                    textAlign: 'center',
                    height: '100%',
                    minHeight: '100%',
                    width: '100%',
                  }}
                >
                  {pageLabel ? (
                    <>
                      <NrLogo />
                      {welcomeMessage && welcomeMessage !== 'Welcome to Goldilocks' ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mb: 6, mt: 2 }}
                        >
                          <img
                            style={{ display: 'inline' }}
                            src="https://res.cloudinary.com/net-results/image/upload/v1683739378/SPA/Bears/bear-zen.png"
                            height="80"
                            alt="the best bear"
                          />
                          <Box>
                            <Typography variant="h1" style={{ color: '#444', fontWeight: 400 }}>
                              {welcomeMessage}
                            </Typography>
                            <Typography variant="h6">
                              The marketing automation solution that&apos;s <i>just right</i>.
                            </Typography>
                          </Box>
                          <img
                            style={{ display: 'inline' }}
                            src="https://res.cloudinary.com/net-results/image/upload/v1683739378/SPA/Bears/bear-thumbs-up.png"
                            height="80"
                            alt="the best bear"
                          />
                        </Stack>
                      ) : null}

                      {/* PAGE LABEL */}
                      <Box sx={{ mt: 2, mb: 4 }}>
                        <Typography variant="h2" style={{ color: '#444', fontWeight: 400 }}>
                          {pageLabel}
                        </Typography>
                      </Box>
                    </>
                  ) : null}

                  {queryParams?.key === 1 ? (
                    <Stack
                      direction="column"
                      spacing={2}
                      mt={4}
                      mb={7}
                      p={2}
                      sx={{
                        backgroundColor: 'background.paper',
                        borderTop: '1px solid',
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                      }}
                    >
                      <Typography variant="h6">
                        Your organization has chosen to prevent login to Net-Results Classic.
                      </Typography>
                      <Typography>Please login here at app.net-results.com in the future.</Typography>
                    </Stack>
                  ) : null}

                  {/* AUTH COMPONENT (login, password reset, etc. */}
                  <Box
                    sx={{
                      maxWidth: pageLabel ? '400px' : 'inherit',
                      margin: '0 auto',
                    }}
                  >
                    {componentToRender}
                  </Box>
                  {welcomeMessage && isGoldilocks ? (
                    <Box sx={{ mt: 6 }}>
                      <Box
                        sx={{
                          p: 2,
                          display: 'inline-block',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 4,
                          backgroundColor: (theme) => theme.palette.background.paper,
                        }}
                      >
                        <Typography variant="h6" sx={{ margin: 0 }}>
                          Goldilocks is the next-generation of the Net-Results platform. Learn more{' '}
                          <a
                            href="https://www.net-results.com/blog/introducing-goldilocks-by-net-results/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            here
                          </a>
                          .
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Slide>
            </Grid>

            {/* RIGHT SIDEBAR / CHANGELOG */}
            <Grid item xs={5} sx={{ backgroundColor: '#29293f', height: '100vh' }}>
              <LoginPageMessages />
            </Grid>
            {/* <Grid item xs={6} sx={{ backgroundColor: '#29293f', height: '100vh' }}>
              <Stream />
            </Grid> */}
          </Grid>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AuthLayout;
